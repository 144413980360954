import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell de fins a 10 cm de diàmetre, primer hemisfèric i poc a poc es va obrint. Aquest presenta un disc central dissociat en esquames més o menys triangulars de color marró o un poc rogenc; aquestes són molt atapeïdes i es disposen en forma de teulada. Les làmines són lliures, rosades i finalment color xocolate i el peu és curt i més prim a la part superior. Té una zona marró cap a la base, com una resta de vel universal i presenta un anell penjant estret i amb doble marge. Les espores són marró en massa i ovalades, de 6-7,5 x 4,5-5,5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      